<template>
	<div class="frameCon">
		<lwa2></lwa2>
		
	</div>
</template>

<script>
import lwa2 from '@/components/Amazon/components/lwa2.vue';
export default {
	
	components: {
		lwa2
	},

	data() {
		return {
			
		};
	},

	//创建时
	created() {},
	//编译挂载前
	mounted() {
	
		
	},
	methods: {
		
	}
};
</script>

<style lang="less" scoped>
	
</style>
